import React, { useEffect, useRef, useState } from "react";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { AuditsTable } from "./AuditTable";
import { AuditsPanelContain } from "./AuditPanelContain";
import { Panel } from "../_slidePanel/Panel";
import { useFetchAudit, useFetchAuditById } from "../../hooks/useAudit";
import { useAuditStore } from "../../store/auditStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { viewTypes } from "../../common/types/views.type";
import auditService from "../../services/audit.service";
import { DeleteDialog } from "../DeleteDialog";
import { useFetchGroup } from "../../hooks/useGroup";
import { useFetchUser } from "../../hooks/useUser";
import { SwitchDialog } from "../SwitchDialog";

export default function AuditsAbm({ resetView }) {
  const { audit, setAudit, setUsers, setAllGroups, cleanAll } = useAuditStore(
    (state) => state
  );

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  /** */
  const _filterUser = {
    pageSize: 8000,
  };

  const fetchUsers = useFetchUser(_filterUser);
  useEffect(() => {
    if (fetchUsers.data) {
      setUsers(fetchUsers.data);
    }
  }, [fetchUsers.data]);

  /** */
  const _filterGroups = {
    size: 8000,
  };

  const fetchGroups = useFetchGroup(_filterGroups);
  useEffect(() => {
    if (fetchGroups.data) {
      setAllGroups(fetchGroups.data.groups);
    }
  }, [fetchGroups.data]);
  /** */

  const [auditId, setAuditId] = useState(null);
  const {
    data: auditData,
    isLoading: auditLoading,
    error: errorAudit,
    refetch: refetchAudit,
  } = useFetchAuditById(auditId);

  useEffect(() => {
    if (auditData) {
      setAudit(auditData);
    }
  }, [auditData]);
  /** */

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    size: 10,
    page: 1,
    sortBy: null,
    direction: null,
    name: "",
    active: "",
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterAudit = (params) => ({
    ...params,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterAudit, setFilterAudit] = useState(buildFilterAudit(initParams));

  useEffect(() => {
    setFilterAudit(buildFilterAudit(lazyParams));
  }, [lazyParams]);

  const fetchAudit = useFetchAudit(filterAudit);

  useEffect(() => {
    if (fetchAudit.data) {
      setTotalRecords(fetchAudit.data.pagination.count);
      setCustomers(fetchAudit.data.audits);
    }
    if (fetchAudit.isLoading !== loading) {
      setLoading(fetchAudit.isLoading);
    }
  }, [fetchAudit.data, fetchAudit.isLoading]);

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        size: event.rows,
      };
    });
  };

  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.size}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const closePanel = () => {
    setAuditId(null);
    setSubmitted(false);
    cleanAll();
    setIsOpen(false);
  };

  const openNew = () => {
    cleanAll();
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const openEdit = (data) => {
    setAuditId(data.id);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const saveAudit = () => {
    if (audit.id === "") {
      const { id, ...auditSinId } = audit;
      mutationCreate.mutate(auditSinId);
    } else {
      mutationUpdate.mutate(audit);
    }
  };

  /** */
  const [deleteDialog, setDeleteDialog] = useState(false);

  const confirmDeleteAudit = (_audit) => {
    setAudit(_audit);
    setDeleteDialog(true);
  };

  const deleteAudit = () => {
    const _id = audit.id;
    mutationDel.mutate(_id);
  };
  /** */
  const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  /** */

  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  const mutationCreate = useMutation({
    mutationFn: (audit) => {
      return auditService.crearAudit(audit, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["audit"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Alta realizada correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el alta",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  const mutationUpdate = useMutation({
    mutationFn: (audit) => {
      return auditService.editarAudit(audit, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["audit"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Cambio realizado correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  const mutationDel = useMutation({
    mutationFn: (id) => {
      return auditService.borrarAudit(id, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["audit"] });
      setDeleteDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Borrar kpi",
        detail: "Eliminada correctamente",
        life: 3000,
      });
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      setDeleteDialog(false);
      toast.current.show({
        severity: "error",
        summary: "Error en el borrado",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });
  /** */

  const [switchAuditDialog, setSwitchAuditDialog] = useState(false);

  const confirmSwitchAudit = (_audit) => {
    setAudit(_audit);
    setSwitchAuditDialog(true);
  };

  const switchAudit = () => {
    const updatedAudit = { ...audit, active: !audit.active };
    mutationUpdate.mutate(updatedAudit);
    setSwitchAuditDialog(false);
  };

  return (
    <>
      <div className="card">
        <Toast ref={toast} />
        <AuditsTable
          openNew={openNew}
          openEdit={openEdit}
          customers={customers}
          loading={loading}
          footerPage={footerPage}
          resetView={resetView}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          confirmDeleteAudit={confirmDeleteAudit}
          confirmSwitchAudit={confirmSwitchAudit}
        />
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <AuditsPanelContain
            closePanel={closePanel}
            submitted={submitted}
            setSubmitted={setSubmitted}
            saveAudit={saveAudit}
          />
        </Panel>
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          functionDelete={deleteAudit}
          nameDelete={audit.name}
          typeDelete="Speech Analytic"
        />
        <SwitchDialog
          audit={audit}
          switchAuditDialog={switchAuditDialog}
          setSwitchAuditDialog={setSwitchAuditDialog}
          switchAudit={switchAudit}
        />
      </div>
    </>
  );
}
