import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { styleEstado } from "../../utils/option.util";
import { FilterPanel } from "./FilterPanel";

export const KpiFormsTable = ({
  openNew,
  openEdit,
  customers,
  loading,
  footerPage,
  resetView,
  lazyParams,
  setLazyParams,
  initParams,
  confirmDeleteAudit,
  confirmSwitchAudit,
}) => {
  const onSort = (event) => {
    setLazyParams((previousState) => {
      return {
        ...previousState,
        sortBy: event.sortField,
        direction: event.sortOrder,
      };
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Speech Analytics</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={
          "customer-badge status-" +
          styleEstado(rowData.active) +
          " text-status"
        }
      >
        {rowData.active ? "Activo" : "Inactivo"}
      </span>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openEdit(rowData)}
        />
        <Button
          icon={rowData.active ? "pi pi-unlock" : "pi pi-lock"}
          className={
            rowData.active
              ? "p-button-rounded p-button-success mr-2"
              : "p-button-rounded p-button-danger mr-2"
          }
          onClick={() => confirmSwitchAudit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteAudit(rowData)}
        />
      </div>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={onSort}
        sortField={lazyParams.sortBy}
        sortOrder={lazyParams.direction}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="active"
          sortable
          showFilterMenu={false}
          header="Estado"
          alignHeader="center"
          bodyClassName={"centerRowData"}
          body={statusBodyTemplate}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
