import { PickList } from "primereact/picklist";
import { useState, useEffect } from "react";
import { useGroupStore } from "../../store/groupStore";

const PicklistComp = ({ returnKpisValue }) => {
  const { group, allKpis, errors } = useGroupStore((state) => state);

  const [sourceList, setSourceList] = useState([]);
  const [targetList, setTargetList] = useState([]);

  const initializePickList = () => {
    const groupKpis = group.kpis || [];
    const availableKpis = allKpis || [];

    const sourceItems = availableKpis.filter(
      (kpi) => !groupKpis.some((gkpi) => gkpi.id === kpi.id)
    );

    setSourceList(sourceItems);
    setTargetList(groupKpis);
  };

  useEffect(() => {
    initializePickList();
  }, [allKpis, group]);

  const onChangeList = (event) => {
    setSourceList(event.source);
    setTargetList(event.target);
    returnKpisValue(event.target);
  };

  const itemTemplate = (item) => (
    <div className="item-container">
      <div className="item-name" style={{ fontWeight: "600" }}>
        {item.label}
      </div>
      <div
        className="item-definition"
        style={{ fontSize: "12px", color: "#939393" }}
      >
        {item.definition}
      </div>
    </div>
  );

  return (
    <>
      <PickList
        style={{
          border: "1px solid #ced4da",
          borderColor: errors.kpis ? "#f44336" : "#ced4da",
          padding: "16px",
          borderRadius: "3px",
        }}
        source={sourceList}
        target={targetList}
        itemTemplate={itemTemplate}
        onChange={onChangeList}
        sourceHeader="Disponibles"
        targetHeader="Seleccionados"
        sourceStyle={{ height: "280px" }}
        targetStyle={{ height: "280px" }}
        filterBy="label"
        sourceFilterPlaceholder="Buscar por nombre"
        targetFilterPlaceholder="Buscar por nombre"
        showSourceControls={false}
        showTargetControls={false}
      />
      {errors.kpis && <small className="p-error block">{errors.kpis}</small>}
    </>
  );
};

export default PicklistComp;
