import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import kpiService from "../services/kpi.service";

const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

// Fetch kpi
const fetchKpi = async (filter) => {
  const response = await kpiService.Obtenerkpis(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchKpi(filter) {
  return useQuery({
    queryKey: ["kpi", filter],
    queryFn: () => fetchKpi(filter),
  });
}
