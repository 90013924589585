import { create } from "zustand";

const auditEmpty = {
  id: "",
  name: "",
  icon: "",
  definition: "",
  userId: "",
  active: false,
  requiredFields: ["NOMBRE_AGENTE", "NOMBRE_AUDIO"],
  optionalFields: [],
  groupKpis: [],
};

const initErrors = {
  name: null,
  userId: null,
  requiredFields: null,
  groupKpis: null,
};

export const useAuditStore = create((set) => ({
  audit: auditEmpty,
  errors: initErrors,
  allGroups: null,
  users: null,

  setAudit: (audit) => set({ audit }),

  updateAuditProp: (propName, value) =>
    set((state) => {
      return {
        audit: {
          ...state.audit,
          [propName]: value,
        },
      };
    }),

  /** */
  setErrors: (errors) => set({ errors }),

  resetErrors: () => {
    errors = initErrors;
  },

  setAllGroups: (allGroups) => set({ allGroups }),
  setUsers: (users) => set({ users }),

  cleanAll: () => {
    set(() => ({
      audit: auditEmpty,
    }));
    set(() => ({
      errors: initErrors,
    }));
  },
}));
