import { create } from "zustand";

const groupEmpty = {
  id: "",
  name: "",
  icon: "",
  definition: "",
  description: "",
  useAI: false,
};

const initErrors = {
  name: null,
  definition: null,
  kpis: null,
};

export const useGroupStore = create((set) => ({
  group: groupEmpty,
  errors: initErrors,
  allKpis: null,

  setGroup: (group) => set({ group }),

  updateGroupProp: (propName, value) =>
    set((state) => {
      return {
        group: {
          ...state.group,
          [propName]: value,
        },
      };
    }),

  /** */
  setErrors: (errors) => set({ errors }),

  resetErrors: () => {
    errors = initErrors;
  },

  setAllKpis: (allKpis) => set({ allKpis }),

  cleanAll: () => {
    set(() => ({
      group: groupEmpty,
    }));
    set(() => ({
      errors: initErrors,
    }));
  },
}));
