import React, { useState, useEffect, useRef } from "react";
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { KpiPanelContain } from "./KpiPanelContain";
import { KpiTable } from "./KpiTable";
import { Panel } from "../_slidePanel/Panel";
import { useFetchDataType } from "../../hooks/useDataType";
import { useFetchKpi } from "../../hooks/useKpi";
import { useKpiStore } from "../../store/kpiStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { viewTypes } from "../../common/types/views.type";
import kpiService from "../../services/kpi.service";
import { DeleteDialog } from "../DeleteDialog";

export default function KpikAbm({ resetView }) {
  const { setDataTypes, kpi, setKpi, cleanAll } = useKpiStore((state) => state);

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  /** */
  const fetchDataTypes = useFetchDataType();
  useEffect(() => {
    if (fetchDataTypes.data) {
      setDataTypes(fetchDataTypes.data);
    }
  }, [fetchDataTypes.data]);
  /** */
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    size: 10,
    page: 1,
    sortBy: null,
    direction: null,
    label: "",
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterKpi = (params) => ({
    ...params,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterKpi, setFilterKpi] = useState(buildFilterKpi(initParams));

  useEffect(() => {
    setFilterKpi(buildFilterKpi(lazyParams));
  }, [lazyParams]);

  const { data, isLoading } = useFetchKpi(filterKpi);

  useEffect(() => {
    if (data) {
      setTotalRecords(data.pagination.count);
      setCustomers(data.kpis);
    }
    if (isLoading !== loading) {
      setLoading(isLoading);
    }
  }, [data, isLoading]);

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        size: event.rows,
      };
    });
  };

  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.size}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const closePanel = () => {
    setSubmitted(false);
    cleanAll();
    setIsOpen(false);
  };

  const openNew = () => {
    cleanAll();
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const openEdit = (data) => {
    setKpi(data);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const saveKpi = () => {
    if (kpi.id === "") {
      const { id, ...kpiSinId } = kpi;
      mutationCreate.mutate(kpiSinId);
    } else {
      mutationUpdate.mutate(kpi);
    }
  };

  /** */
  const [deleteDialog, setDeleteDialog] = useState(false);

  const confirmDeleteKpi = (_kpi) => {
    setKpi(_kpi);
    setDeleteDialog(true);
  };

  const deleteKpi = () => {
    const _id = kpi.id;
    mutationDel.mutate(_id);
  };
  /** */
  const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  /** */

  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  const mutationCreate = useMutation({
    mutationFn: (kpi) => {
      return kpiService.crearKpi(kpi, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["kpi"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Alta realizada correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el alta",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  const mutationUpdate = useMutation({
    mutationFn: (kpi) => {
      return kpiService.editarKpi(kpi, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["kpi"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Cambio realizado correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */

  const mutationDel = useMutation({
    mutationFn: (id) => {
      return kpiService.borrarKpi(id, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["kpi"] });
      setDeleteDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Borrar kpi",
        detail: "Eliminado correctamente",
        life: 3000,
      });
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      setDeleteDialog(false);
      toast.current.show({
        severity: "error",
        summary: "Error en el borrado",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  return (
    <>
      <div className="card">
        <Toast ref={toast} />
        <KpiTable
          openNew={openNew}
          openEdit={openEdit}
          customers={customers}
          loading={loading}
          footerPage={footerPage}
          resetView={resetView}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          confirmDeleteKpi={confirmDeleteKpi}
        />
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <KpiPanelContain
            closePanel={closePanel}
            submitted={submitted}
            setSubmitted={setSubmitted}
            saveKpi={saveKpi}
          />
        </Panel>
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          functionDelete={deleteKpi}
          nameDelete={kpi.label}
          typeDelete="kpi"
        />
      </div>
    </>
  );
}
