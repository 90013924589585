import http from "../common/components/http-common";

export const getDataTypes = (
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>("/v1/kpi_data_type", config);
};
