export const viewTypes = {
  vnHome: "HOME",
  vfVerCampanasActivasSalientes: "VER CAMPANAS ACTIVAS SALIENTES",
  vfVerCampanasActivasEntrantes: "VER CAMPANAS ACTIVAS ENTRANTES",
  vfVerCampanasFinalizadas: "VER CAMPANAS FINALIZADAS",

  vnConfiguracion: "CONFIGURACION",
  vfAbmVoicebots: "ABM VOICEBOTS",
  vfAbmCategorias: "ABM CATEGORIAS",
  vfAbmGrupos: "ABM GRUPOS",
  vfAbmClientes: "ABM CLIENTES",
  vfAbmUsuarios: "ABM USUARIOS",
  vfAbmAsterisks: "ABM ASTERISKS",
  vfAbmProveedores: "ABM PROVEEDORES",
  vfAbmEntidades: "ABM ENTIDADES",
  vfAbmIntenciones: "ABM INTENCIONES",
  vfAbmBodyWs: "ABM BODY WS",
  vfAbmAnalytics: "ABM ANALYTICS",
  vfAbmLocuciones: "ABM LOCUCIONES",
  vfVerConfiguracion: "VER CONFIGURACION",
  vfAbmGruposUsuarios: "ABM GRUPOS USUARIOS",
  vfVerPermisos: "VER PERMISOS",
  vfAbmKpis: "ABM KPIS",
  vfAbmGruposKpis: "ABM GRUPOS KPIS",
  vfABmFromulariosAuditorias: "ABM FROMULARIOS AUDITORIAS",
  vfConfigSitio: "VER CONFIG SITIO",
  vfConfigLlamados: "VER CONFIG LLAMADOS",
  vfConfigVoicebots: "VER CONFIG VOICEBOTS",
  vfConfigAuditorias: "VER CONFIG AUDITORIAS",

  vnAuditorias: "AUDITORIAS",
  vfVerAuditorias: "VER AUDITORIAS",

  vnListadoCampana: "LISTADO_CAMPANA",
  vfVerReportes: "VER REPORTES",
  vfIrDetalleCampana: "IR DETALLE CAMPANA",
  vfDescargarReportesLis: "DESCARGAR REPORTES",
  vfVerListadoCampana: "VER LISTADO CAMPANA",
  vfVerAltaCampanaSaliente: "VER ALTA CAMPANA SALIENTE",
  vfVerAltaCampanaSalienteReducido: "VER ALTA CAMPANA SALIENTE REDUCIDO",
  vfVerAltaCampanaEntrante: "VER ALTA CAMPANA ENTRANTE",
  vfVerEdicionCampana: "VER EDICION CAMPANA",
  vfVerTodasLasCampanas: "VER TODAS LAS CAMPANAS",
  vfVerDuracion: "VER DURACION",
  vfElimnarCampana: "ELIMINAR CAMPANA",

  vnDetalleCampana: "DETALLE_CAMPANA",
  vfDescargarReportesDet: "DESCARGAR REPORTES",
  vfIrDetalleLlamado: "IR DETALLE LLAMADO",
  vfControlarCampana: "CONTROLAR CAMPANA",
  vfVerDashborad: "VER DASHBOARD",
  vfVerDetalleCampana: "VER DETALLE CAMPANA",
  vfMostrarCampana: "MOSTRAR CAMPANA",
  vfMostrarAudio: "MOSTRAR AUDIO",
  vfMostrarReprocesamiento: "MOSTRAR REPROCESAMIENTO",
  vfFuncionPlayPauseStop: "FUNCION PLAY, PAUSE, STOP",
  vfVerSeteosCampana: "VER SETEOS CAMPANA",
  vfVerColumnasAvanzadas: "VER COLUMNAS AVANZADAS",
  vfVistaGraficoAvanzado: "VISTA GRAFICO AVANZADO",
  vfDescargarArchivo: "DESCARGAR ARCHIVO",

  vnDetalleLlamado: "DETALLE_LLAMADO",
  vfVerAudio: "VER AUDIO",
  vfVerAudioCPN: "VER AUDIO C-PLL-NSPC",
  vfVerDetalleLlamado: "VER DETALLE LLAMADO",

  vnMonitoring: "MONITORING",
  vfVerMonitoring: "VER MONITORING",
  vfRestartProdCon: "RESTART PRODCON",

  vnVoicebot: "VOICEBOT",
  vfVerVoicebot: "VER VOICEBOT",

  vnGeneral: "GENERAL",
  vfEstadoDeCampanas: "ESTADOS DE CAMPANAS",

  vnAnalytics: "ANALYTICS",
  vfVerAnalytics: "VER ANALYTICS",
};
