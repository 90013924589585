import http from "../common/components/http-common";

class KpiService {
  Obtenerkpis(
    params,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
    };
    return http.get<any>("/v1/kpi", config);
  }

  crearKpi(data, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post("/v1/kpi", data, config);
  }

  editarKpi = (data, userName, viewName, viewFunction) => {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>(`/v1/kpi/${data.id}`, data, config);
  };

  borrarKpi(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.delete<any>("/v1/kpi/" + id, config);
  }
}

export default new KpiService();
