import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import groupService from "../services/group.service";

const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

// Fetch group
const fetchGroup = async (filter) => {
  const response = await groupService.ObtenerGrupos(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchGroup(filter) {
  return useQuery({
    queryKey: ["group", filter],
    queryFn: () => fetchGroup(filter),
  });
}
