import React, { useEffect } from "react";
import { Button, InputText, Toolbar, InputTextarea } from "primereact";
import PicklistComp from "./PicklistComp";
import { useGroupStore } from "../../store/groupStore";
import IconPicker from "../IconPicker";
import { classNames } from "primereact/utils";

export const KpiGroupsPanelContain = ({
  closePanel,
  submitted,
  setSubmitted,
  saveGroup,
}) => {
  const { group, errors, setErrors, updateGroupProp } = useGroupStore(
    (state) => state
  );

  useEffect(() => {
    submitted && validateForm();
  }, [group, submitted]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!group.name?.trim()) {
      newErrors.name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.name = null;
    }

    if (!group.definition?.trim()) {
      newErrors.definition = "Ingresa una definición";
      valid = false;
    } else {
      newErrors.definition = null;
    }

    if (!group.kpis || group.kpis.length === 0) {
      newErrors.kpis = "Selecciona los kpis";
      valid = false;
    } else {
      newErrors.kpis = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle grupo Kpis</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveGroup();
    }
  };

  const returnIconValue = (value) => {
    updateGroupProp("icon", value);
  };

  const returnKpisValue = (value) => {
    updateGroupProp("kpis", value);
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <>
      <div className="body">
        <div className="column">
          <div className="field name">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={group.name}
              onChange={(e) => updateGroupProp("name", e.target.value)}
              required
              autoFocus
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && errors.name,
              })}
              placeholder="Ingresa el nombre del gurpo"
            />
            {errors.name && (
              <small className="p-error block">{errors.name}</small>
            )}
          </div>

          <div className="field">
            <label htmlFor="definition">Definición</label>
            <InputTextarea
              value={group.definition}
              onChange={(e) => updateGroupProp("definition", e.target.value)}
              rows={6}
              cols={30}
              className={classNames({
                "p-invalid": submitted && errors.definition,
              })}
            />
            {errors.definition && (
              <small className="p-error block">{errors.definition}</small>
            )}
          </div>
        </div>

        <div className="column">
          <div className="field">
            <label htmlFor="icon">Icono</label>
            <IconPicker
              type="group"
              value={group.icon}
              returnIconValue={returnIconValue}
            />
          </div>

          <div className="field">
            <label htmlFor="descirption">Descripción</label>
            <InputTextarea
              value={group.description}
              onChange={(e) => updateGroupProp("description", e.target.value)}
              rows={4}
              cols={30}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "0 16px",
          maxWidth: "1420px",
        }}
      >
        <div className="field">
          <label htmlFor="kpis">Kpis</label>
          <PicklistComp returnKpisValue={returnKpisValue} />
        </div>
      </div>
    </>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
