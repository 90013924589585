import React, { useEffect, useState } from "react";
import {
  Button,
  InputSwitch,
  InputText,
  Toolbar,
  Dropdown,
  InputTextarea,
} from "primereact";
import { useAuditStore } from "../../store/auditStore";
import { TreeComponent } from "./TreeComponent";
import AditionalsComponent from "./AditionalsComponent";
import IconPicker from "../IconPicker";
import { classNames } from "primereact/utils";

export const AuditsPanelContain = ({
  closePanel,
  submitted,
  setSubmitted,
  saveAudit,
}) => {
  const { audit, errors, setErrors, updateAuditProp, updateUserProps, users } =
    useAuditStore((state) => state);

  useEffect(() => {
    submitted && validateForm();
  }, [audit, submitted]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!audit.name?.trim()) {
      newErrors.name = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.name = null;
    }

    if (!audit.userId) {
      newErrors.userId = "Selecciona un usuario";
      valid = false;
    } else {
      newErrors.userId = null;
    }

    if (!audit.groupKpis || audit.groupKpis.length === 0) {
      newErrors.groupKpis = "Selecciona los grupos";
      valid = false;
    } else {
      newErrors.groupKpis = null;
    }

    if (!audit.requiredFields || audit.requiredFields.length === 0) {
      newErrors.requiredFields = "Ingresa los campos obligatorios";
      valid = false;
    } else {
      newErrors.requiredFields = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const returnIconValue = (value) => {
    updateAuditProp("icon", value);
  };

  const returnAditionalValue = (name, value) => {
    updateAuditProp(name, value);
  };

  const returnGroupsValue = (value) => {
    updateAuditProp("groupKpis", value);
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle Speech Analytics</div>;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveAudit();
    }
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const preUpdate = () => {};

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={audit.name}
              onChange={(e) => updateAuditProp("name", e.target.value)}
              required
              autoFocus
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && errors.name,
              })}
              placeholder="Ingresa el nombre"
            />
            {errors.name && (
              <small className="p-error block">{errors.name}</small>
            )}
          </div>

          <div className="field switch">
            <InputSwitch
              checked={audit.active}
              onChange={(e) => updateAuditProp("active", e.value)}
            />
            <label htmlFor="active">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="user">Usuario</label>
          <Dropdown
            value={users && users.find((user) => user.id === audit.userId)}
            onChange={(e) => updateUserProps(e.value)}
            options={users}
            optionLabel="name"
            placeholder="Selecciona un usuario"
            className={classNames({
              "p-invalid": submitted && errors.userId,
            })}
          />
          {errors.userId && (
            <small className="p-error block">{errors.userId}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="aditionals">Variables obligatorias</label>
          <AditionalsComponent
            required={true}
            returnAditionalValue={returnAditionalValue}
          />
        </div>

        <div className="field">
          <label htmlFor="aditionals">Variables adicionales</label>
          <AditionalsComponent
            required={false}
            returnAditionalValue={returnAditionalValue}
          />
        </div>

        <div className="field">
          <label htmlFor="definition">Definición</label>
          <InputTextarea
            value={audit.definition}
            onChange={(e) => updateAuditProp("definition", e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
      </div>

      <div className="column">
        <div className="field">
          <label htmlFor="icon">Icono</label>
          <IconPicker
            type="form"
            value={audit.icon}
            returnIconValue={returnIconValue}
          />
        </div>

        <div className="field">
          <label htmlFor="gurpos">Grupos y KPIs</label>
          <TreeComponent returnGroupsValue={returnGroupsValue} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
