import http from "../common/components/http-common";

class GroupService {
  ObtenerGrupos(
    params,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: params,
    };
    return http.get<any>("/v1/kpi_group", config);
  }
  crearGroup(data, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.post("/v1/kpi_group", data, config);
  }

  editarGroup = (data, userName, viewName, viewFunction) => {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.put<any>(`/v1/kpi_group/${data.id}`, data, config);
  };

  borrarGroup(id, userName: string, viewName: string, viewFunction: string) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.delete<any>("/v1/kpi_group/" + id, config);
  }
}

export default new GroupService();
