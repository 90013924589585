import http from "../common/components/http-common";

class UserService {
  ObtenerUsuarios(
    filters,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: filters,
    };

    return http.get<any>("/v1/users/", config);
  }
}

export default new UserService();
