import React from "react";
import { Button, Dialog } from "primereact";

export const SwitchDialog = ({
  audit,
  switchAuditDialog,
  setSwitchAuditDialog,
  switchAudit,
}) => {
  const switchDialogFooter = (
    <>
      <Button
        label="Si"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => switchAudit()}
      />
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setSwitchAuditDialog(false)}
      />
    </>
  );

  return (
    <Dialog
      visible={switchAuditDialog}
      header="Cambiar estado Speech Analytic"
      modal
      footer={switchDialogFooter}
      onHide={() => setSwitchAuditDialog(false)}
      style={{ width: "400px", maxWidth: "95%" }}
    >
      <div className="flex align-items-center justify-content-center">
        <i
          className="pi pi-exclamation-triangle mr-3"
          style={{ fontSize: "2rem" }}
        />
        {audit && (
          <span>
            ¿Estas seguro de que quieres{" "}
            {audit.active ? "desactivar" : "activar"} <b>{audit.name}</b>?
          </span>
        )}
      </div>
    </Dialog>
  );
};
