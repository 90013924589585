import React, { useEffect, useState } from "react";
import { Tree } from "primereact/tree";
import { useAuditStore } from "../../store/auditStore";

export const TreeComponent = ({ returnGroupsValue }) => {
  const { audit, allGroups, errors } = useAuditStore((state) => state);
  const selectedGroupKpis = audit.groupKpis || [];

  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});

  const parseData = (data) => {
    return (
      data
        ?.filter((group) => group.kpis && group.kpis.length > 0)
        .map((group) => ({
          key: group.id,
          label: group.name,
          icon: group.icon,
          children: group.kpis?.map((kpi) => ({
            key: kpi.id,
            label: kpi.label,
            icon: kpi.icon,
            description: kpi.definition,
            selectable: false,
          })),
        })) || []
    );
  };

  useEffect(() => {
    const parsedData = parseData(allGroups);
    setTreeData(parsedData);

    const keys = {};
    selectedGroupKpis.forEach(({ groupId }) => {
      keys[groupId] = { checked: true, partialChecked: false };
    });
    setSelectedKeys(keys);
  }, [allGroups, selectedGroupKpis]);

  const onSelectionChange = (e) => {
    const newSelectedKeys = e.value;

    const selectedGroups = allGroups
      .filter((group) => newSelectedKeys[group.id])
      .map((group) => ({
        groupId: group.id,
        kpiIds: group.kpis.map((kpi) => kpi.id),
      }));

    returnGroupsValue(selectedGroups);
    setSelectedKeys(newSelectedKeys);
  };

  const nodeTemplate = (node) => (
    <div className="node-container">
      <div className="node">
        <div className="node-label">{node.label}</div>
        {node.description && (
          <div className="node-description">{node.description}</div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Tree
        style={{
          border: "1px solid",
          borderColor: errors.groupKpis ? "#f44336" : "#ced4da",
        }}
        value={treeData}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={onSelectionChange}
        nodeTemplate={nodeTemplate}
        filter
      />
      {errors.groupKpis && (
        <small className="p-error block">{errors.groupKpis}</small>
      )}
    </>
  );
};
