import React from "react";
import { Button, Column, DataTable, Toolbar } from "primereact";
import { FilterPanel } from "./FilterPanel";

export const KpiGroupsTable = ({
  openNew,
  openEdit,
  customers,
  footerPage,
  loading,
  resetView,
  lazyParams,
  setLazyParams,
  initParams,
  confirmDeleteGroup,
}) => {
  const leftToolbarTemplate = () => {
    return (
      <div className="back-to-config">
        <div className="mobile">
          <button className="cog" onClick={() => resetView()}>
            <i className="pi pi-cog"></i>
          </button>
          <i className="pi pi-chevron-left"></i>
        </div>
        <div className="section-title">Grupos KPIs</div>
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label=""
        icon="pi pi-plus"
        className="p-button-success mr-2"
        onClick={openNew}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openEdit(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteGroup(rowData)}
        />
      </div>
    );
  };

  const useAIBodyTemplate = (rowData) => {
    return (
      <i
        style={{
          fontSize: "20px",
          color: rowData.useAI ? "#689F38" : "#D32F2F",
        }}
        className={rowData.useAI ? "pi pi-check" : "pi pi-times"}
      ></i>
    );
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>

      <FilterPanel
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        initParams={initParams}
      />

      <DataTable
        value={customers}
        responsiveLayout="stack"
        dataKey="id"
        stripedRows
        onSort={null}
        sortField={null}
        sortOrder={null}
        loading={loading}
        emptyMessage="Sin resultados"
        footer={footerPage}
      >
        <Column
          field="name"
          showFilterMenu={false}
          sortable
          header="Nombre"
          alignHeader="left"
        />
        <Column
          field="definition"
          showFilterMenu={false}
          sortable
          header="Definición"
          alignHeader="left"
        />
        <Column
          showFilterMenu={false}
          header="Usa AI"
          alignHeader="center"
          body={useAIBodyTemplate}
          bodyClassName={"centerRowData"}
        />
        <Column
          bodyStyle={{ textAlign: "end", justifyContent: "end" }}
          body={actionBodyTemplate}
          exportable={false}
        />
      </DataTable>
    </>
  );
};
