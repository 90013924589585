import http from "../common/components/http-common";

class AuditAudioService {
  obtenerAudios(
    filters,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: filters,
    };
    return http.get<any>("/v1/auditAudio/", config);
  }

  borrarAuditAudio(
    id,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.delete<any>("/v1/auditAudio/" + id, config);
  }

  CrearAuditAudio(
    data: any,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };

    return http.post<any>("/v1/auditAudio/", data, config);
  }

  obtenerAudioFile(
    id,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      responseType: "blob" as const,
    };
    return http.get(`/v1/auditAudio/${id}/audio`, config);
  }
}

export default new AuditAudioService();
