import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import { getDataTypes } from "../services/dataType.service";

const fetchDataType = async () => {
  const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  const response = await getDataTypes(userName, viewName, viewFunction);
  return response.data;
};

export function useFetchDataType() {
  return useQuery({
    queryKey: ["dataType"],
    queryFn: () => fetchDataType(),
    retry: false,
    refetchOnWindowFocus: false,
  });
}
