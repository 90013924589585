import KpiAbm from "./kpi/KpiAbm";
import KpiFormsAbm from "./kpiForms/KpiFormsAbm";
import KpiGroupsAbm from "./kpiGroups/KpiGroupsAbm";

export default function ViewsConfig() {
  const resetView = () => {
    window.location.href = "/configuration";
  };
  const optionView = sessionStorage.getItem("optionView");

  const selectView = () => {
    switch (optionView) {
      case "KPIs":
        return <KpiAbm resetView={resetView} />;
      case "Grupos KPIs":
        return <KpiGroupsAbm resetView={resetView} />;
      case "Formularios":
        return <KpiFormsAbm resetView={resetView} />;
      default:
        return <NullView />;
    }
  };

  return <>{selectView()}</>;
}

const NullView = () => {
  return <></>;
};
