import React, { useEffect } from "react";

const kpiIcons = [
  "pi pi-file",
  "pi pi-phone",
  "pi pi-cog",
  "pi pi-clock",
  "pi pi-android",
  "pi pi-box",
  "pi pi-bookmark",
  "pi pi-chart-pie",
  "pi pi-chart-bar",
  "pi pi-cloud",
  "pi pi-desktop",
  "pi pi-heart",
  "pi pi-star",
  "pi pi-thumbs-up",
  "pi pi-thumbs-down",
  "pi pi-server",
  "pi pi-globe",
  "pi pi-check-square",
  "pi pi-paperclip",
  "pi pi-trash",
  "pi pi-truck",
  "pi pi-sun",
  "pi pi-home",
  "pi pi-gift",
];

const groupIcons = [
  "pi pi-inbox",
  "pi pi-folder",
  "pi pi-folder-open",
  "pi pi-wallet",
  "pi pi-briefcase",
];

const formIcons = [
  "pi pi-comments",
  "pi pi-users",
  "pi pi-book",
  "pi pi-sliders-h",
  "pi pi-list",
];

const IconPicker = ({ type, value, returnIconValue }) => {
  const icons =
    type === "group"
      ? groupIcons
      : type === "kpi"
      ? kpiIcons
      : type === "form"
      ? formIcons
      : [];

  useEffect(() => {
    if (!value) {
      returnIconValue(icons[0]);
    }
  }, [value, icons, returnIconValue]);

  const handleIconClick = (icon) => {
    returnIconValue(icon);
  };

  return (
    <div
      style={{
        border: "1px solid",
        borderColor: "#ced4da",
        borderRadius: "3px",
        padding: "16px",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {icons.map((icon, index) => (
        <button
          key={index}
          onClick={() => handleIconClick(icon)}
          style={{
            cursor: "pointer",
            margin: "4px",
            border: value === icon ? "2px solid #2196F3" : "none",
            padding: "4px",
            borderRadius: "4px",
            backgroundColor: "transparent",
          }}
        >
          <i
            className={icon}
            style={{
              fontSize: "20px",
              color: "#6b7280",
            }}
          ></i>
        </button>
      ))}
    </div>
  );
};

export default IconPicker;
