import React, { useState, useEffect, useRef } from "react";
import { Paginator } from "primereact/paginator";
import { KpiGroupsPanelContain } from "./KpiGroupsPanelContain";
import { KpiGroupsTable } from "./KpiGroupsTable";
import { Panel } from "../_slidePanel/Panel";
import { useFetchGroup } from "../../hooks/useGroup";
import { useGroupStore } from "../../store/groupStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { viewTypes } from "../../common/types/views.type";
import { DeleteDialog } from "../DeleteDialog";
import groupService from "../../services/group.service";
import { useFetchKpi } from "../../hooks/useKpi";
import { Toast } from "primereact/toast";

export default function KpikGroupsAbm({ resetView }) {
  const { group, setGroup, setAllKpis, cleanAll } = useGroupStore(
    (state) => state
  );

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  /** */
  const _filterKpis = {
    size: 8000,
  };

  const fetcKpis = useFetchKpi(_filterKpis);
  useEffect(() => {
    if (fetcKpis.data) {
      setAllKpis(fetcKpis.data.kpis);
    }
  }, [fetcKpis.data]);
  /** */

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customFirst, setCustomFirst] = useState(0);
  const [customers, setCustomers] = useState(null);

  const initParams = {
    size: 10,
    page: 1,
    sortBy: null,
    direction: null,
    name: "",
  };

  const getDirection = (direction) => (direction === 1 ? "ASC" : "DESC");

  const buildFilterGroup = (params) => ({
    ...params,
    direction: lazyParams.direction ? getDirection(lazyParams.direction) : null,
  });

  const [lazyParams, setLazyParams] = useState(initParams);
  const [filterGroup, setFilterGroup] = useState(buildFilterGroup(initParams));

  useEffect(() => {
    setFilterGroup(buildFilterGroup(lazyParams));
  }, [lazyParams]);

  const { data, isLoading } = useFetchGroup(filterGroup);

  useEffect(() => {
    if (data) {
      setTotalRecords(data.pagination.count);
      setCustomers(data.groups);
    }
    if (isLoading !== loading) {
      setLoading(isLoading);
    }
  }, [data, isLoading]);

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;
  };

  const pageChange = (event) => {
    setCustomFirst(event.first);
    setLazyParams((previousState) => {
      return {
        ...previousState,
        page: event.page + 1,
        size: event.rows,
      };
    });
  };

  /** */
  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={customFirst}
          rows={lazyParams.size}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={pageChange}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const closePanel = () => {
    setSubmitted(false);
    cleanAll();
    setIsOpen(false);
  };

  const openNew = () => {
    cleanAll();
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const openEdit = (data) => {
    setGroup(data);
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  const saveGroup = () => {
    if (group.id === "") {
      const { id, ...groupSinId } = group;
      mutationCreate.mutate(groupSinId);
    } else {
      mutationUpdate.mutate(group);
    }
  };

  /** */
  const [deleteDialog, setDeleteDialog] = useState(false);

  const confirmDeleteGroup = (_group) => {
    setGroup(_group);
    setDeleteDialog(true);
  };

  const deleteGroup = () => {
    const _id = group.id;
    mutationDel.mutate(_id);
  };
  /** */
  const { vnConfiguracion: viewName, vfAbmKpis: viewFunction } = viewTypes;
  const userName = localStorage.getItem("userName");

  /** */

  type ErrorResponse = {
    response?: {
      data?: {
        message?: string;
      };
    };
  };
  const queryClient = useQueryClient();

  const mutationCreate = useMutation({
    mutationFn: (group) => {
      return groupService.crearGroup(group, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Alta realizada correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el alta",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */
  const mutationUpdate = useMutation({
    mutationFn: (group) => {
      return groupService.editarGroup(group, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      closePanel();
      toast.current.show({
        severity: "success",
        summary: "Cambio realizado correctamente",
        life: 3000,
      });
    },
    onError: (error: unknown) => {
      const err = error as ErrorResponse;
      toast.current.show({
        severity: "error",
        summary: "Error en el cambio",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */

  const mutationDel = useMutation({
    mutationFn: (id) => {
      return groupService.borrarGroup(id, userName, viewName, viewFunction);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["group"] });
      setDeleteDialog(false);
      toast.current.show({
        severity: "success",
        summary: "Borrar kpi",
        detail: "Eliminado correctamente",
        life: 3000,
      });
    },
    onError: (error) => {
      const err = error as ErrorResponse;
      setDeleteDialog(false);
      toast.current.show({
        severity: "error",
        summary: "Error en el borrado",
        detail: err.response?.data?.message || "Error desconocido",
        life: 3000,
      });
    },
  });

  /** */

  return (
    <>
      <div className="card">
        <Toast ref={toast} />
        <KpiGroupsTable
          openNew={openNew}
          openEdit={openEdit}
          customers={customers}
          loading={loading}
          footerPage={footerPage}
          resetView={resetView}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          initParams={initParams}
          confirmDeleteGroup={confirmDeleteGroup}
        />
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <KpiGroupsPanelContain
            closePanel={closePanel}
            submitted={submitted}
            setSubmitted={setSubmitted}
            saveGroup={saveGroup}
          />
        </Panel>
        <DeleteDialog
          deleteDialog={deleteDialog}
          setDeleteDialog={setDeleteDialog}
          functionDelete={deleteGroup}
          nameDelete={group.name}
          typeDelete="grupo"
        />
      </div>
    </>
  );
}
