import React, { useEffect } from "react";
import {
  Button,
  InputText,
  Toolbar,
  Dropdown,
  InputTextarea,
} from "primereact";
import IconPicker from "../IconPicker";
import { useKpiStore } from "../../store/kpiStore";
import { classNames } from "primereact/utils";

export const KpiPanelContain = ({
  closePanel,
  submitted,
  setSubmitted,
  saveKpi,
}) => {
  const { dataTypes, kpi, errors, setErrors, updateKpiProp } = useKpiStore(
    (state) => state
  );

  useEffect(() => {
    submitted && validateForm();
  }, [kpi, submitted]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!kpi.label?.trim()) {
      newErrors.label = "Ingresa el nombre";
      valid = false;
    } else {
      newErrors.label = null;
    }

    if (Object.keys(kpi.dataType).length === 0) {
      newErrors.dataType = "Selecciona un tipo";
      valid = false;
    } else {
      newErrors.dataType = null;
    }

    if (!kpi.definition?.trim()) {
      newErrors.definition = "Ingresa una definición";
      valid = false;
    } else {
      newErrors.definition = null;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSave = () => {
    setSubmitted(true);
    if (validateForm()) {
      saveKpi();
    }
  };

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle kpi</div>;
  };

  const returnIconValue = (value) => {
    updateKpiProp("icon", value);
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="field name">
          <label htmlFor="name">Nombre</label>
          <InputText
            value={kpi.label}
            onChange={(e) => updateKpiProp("label", e.target.value)}
            required
            autoFocus
            autoComplete="off"
            className={classNames({
              "p-invalid": submitted && errors.label,
            })}
            placeholder="Ingresa el nombre"
          />
          {errors.label && (
            <small className="p-error block">{errors.label}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="icon">Icono</label>
          <IconPicker
            type="kpi"
            value={kpi.icon}
            returnIconValue={returnIconValue}
          />
        </div>

        <div className="field">
          <label htmlFor="dataType">Tipo de dato</label>
          <Dropdown
            value={kpi.dataType}
            onChange={(e) => updateKpiProp("dataType", e.value)}
            options={dataTypes}
            optionLabel="name"
            className={classNames({
              "p-invalid": submitted && errors.dataType,
            })}
            placeholder="Elije el tipo de dato"
          />
          {errors.dataType && (
            <small className="p-error block">{errors.dataType}</small>
          )}
        </div>

        <div className="field">
          <label htmlFor="definition">Definición</label>
          <InputTextarea
            value={kpi.definition}
            onChange={(e) => updateKpiProp("definition", e.target.value)}
            rows={8}
            cols={30}
            className={classNames({
              "p-invalid": submitted && errors.definition,
            })}
          />
          {errors.definition && (
            <small className="p-error block">{errors.definition}</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
