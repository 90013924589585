import React, { useState, useEffect, useRef } from "react";
import { InputText, Button } from "primereact";
import { Toast } from "primereact/toast";
import { genId } from "../../utils/idGenerator";
import { useAuditStore } from "../../store/auditStore";
import { useFetchAudios } from "../../hooks/useAudios";

const AditionalsComponent = ({ required = false, returnAditionalValue }) => {
  const { audit, errors } = useAuditStore((state) => state);
  const [inputValue, setInputValue] = useState("");
  const toast = useRef(null);

  /** */
  const [audios, setAudios] = useState(null);
  const _filterAudios = {
    size: 8,
    auditId: audit.id,
  };

  const fetchAudios = useFetchAudios(_filterAudios);
  useEffect(() => {
    if (fetchAudios.data) {
      setAudios(fetchAudios.data.audios);
    }
  }, [fetchAudios.data, audit.id]);
  /** */

  const _prop = required ? "requiredFields" : "optionalFields";
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (Array.isArray(audit[_prop])) {
      setTags(audit[_prop].map((label) => ({ label, id: genId() })));
    } else {
      setTags([]);
    }
  }, [audit, _prop]);

  const handleAddTag = () => {
    const trimmedValue = inputValue.trim();
    if (trimmedValue && !tags.some((tag) => tag.label === trimmedValue)) {
      const newTag = {
        label: trimmedValue,
        id: genId(),
      };
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      returnAditionalValue(
        _prop,
        updatedTags.map((tag) => tag.label)
      );
      setInputValue("");
    }
  };

  const handleDeleteTag = (idToDelete) => {
    if (
      audit.id === "" ||
      !required ||
      (required && audios && audios.length === 0)
    ) {
      const newTags = tags.filter((tag) => tag.id !== idToDelete);
      setTags(newTags);
      returnAditionalValue(
        _prop,
        newTags.map((tag) => tag.label)
      );
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Advertencia",
        detail:
          "No se pueden eliminar variables oibligatorias cuando hay audios cargados",
        life: 3000,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9-_ ]*$/.test(value)) {
      setInputValue(value);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div
        className="tag-editor"
        style={{
          border: "1px solid",
          borderColor:
            required && errors.requiredFields ? "#f44336" : "#ced4da",
        }}
      >
        <div className="p-inputgroup">
          <InputText
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Agregar un texto"
          />
          <Button
            label="Agregar"
            onClick={handleAddTag}
            disabled={!inputValue.trim()}
          />
        </div>
        <div className="tag-list">
          {tags.length > 0 ? (
            tags.map((tag) => (
              <div key={tag.id} className="custom-tag">
                {tag.label}
                <button
                  onClick={() => handleDeleteTag(tag.id)}
                  style={{
                    border: 0,
                    background: "transparent",
                    margin: 0,
                    padding: 0,
                    cursor: "pointer",
                  }}
                >
                  <i className="pi pi-times tag-remove-icon"></i>
                </button>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {required && errors.requiredFields && (
        <small className="p-error block">{errors.requiredFields}</small>
      )}
    </>
  );
};

export default AditionalsComponent;
