import { create } from "zustand";

const kpiEmpty = {
  id: "",
  label: "",
  icon: "",
  definition: "",
  description: "",
  dataType: {},
};
const initErrors = {
  label: null,
  dataType: null,
  definition: null,
};

export const useKpiStore = create((set) => ({
  kpi: kpiEmpty,
  dataTypes: null,
  errors: initErrors,

  setKpi: (kpi) => set({ kpi }),
  setDataTypes: (dataTypes) => set({ dataTypes }),

  updateKpiProp: (propName, value) =>
    set((state) => {
      return {
        kpi: {
          ...state.kpi,
          [propName]: value,
        },
      };
    }),

  /** */
  setErrors: (errors) => set({ errors }),

  resetErrors: () => {
    errors = initErrors;
  },

  cleanAll: () => {
    set(() => ({
      kpi: kpiEmpty,
    }));
    set(() => ({
      errors: initErrors,
    }));
  },
}));
